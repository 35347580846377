import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import TicketIcon from '../Icon/TicketIcon'
import { getFormattedDisplayDateWithTimezone } from '../../utils/dates'
// import Tippy from '@tippyjs/react'

const FTEventBanner = (props) => {
	const { event, showRegistration } = props

	const EventInfo = (props) => {
		const { event } = props
		const eventYear = event.date.split('-')[0]

		let boxOfficeLink = ``
		let boxOfficePage = `box-office?date=${event.date}&eventid=${event.ft_id}`

		switch (event.category) {
			case 'childrens':
				boxOfficeLink = `/${eventYear}/childrens-festival-${boxOfficePage}`
				break
			case 'schools':
				boxOfficeLink = `/${eventYear}/schools-${boxOfficePage}`
				break
			default:
				boxOfficeLink = `/${eventYear}/${boxOfficePage}`
				break
		}

		return (
			<div className={`bg-bbf-mid-green text-white p-4 md:px-12 md:opacity-95 md:absolute md:bottom-0 md:left-0 md:right-0 md:flex md:items-center`}>
				<div className={`pb-4 md:pr-4 md:pb-0`}>
					<h1 className={`text-2xl mb-2 md:text-4xl`}>{event.name}</h1>
					<p className={`text-lg md:text-2xl`}>{getFormattedDisplayDateWithTimezone(event.datetime)}</p>
				</div>
				{ showRegistration && (
					<div className={`md:ml-auto`}>
						{/*<Tippy*/}
						{/*	content={(*/}
						{/*		<div className={`p-2 text-lg text-center`}>*/}
						{/*			<p>All 2021 festival events are free.</p>*/}
						{/*			<p><Link to={`/donate`} className={`link text-white`}>Make a donation to show your support.</Link></p>*/}
						{/*		</div>*/}
						{/*	)}*/}
						{/*	animation="perspective"*/}
						{/*	placement="top"*/}
						{/*	interactive="true"*/}
						{/*	arrow={true}*/}
						{/*	inertia={true}*/}
						{/*>*/}
							<Link
								to={boxOfficeLink}
								state={{ eventId: event.ft_id, eventCategory: event.category }}
								title={`Buy tickets for ${event.name}`}
								className={`md:text-2xl p-2 md:p-4 bg-bbf-yellow text-black shadow-md flex items-center justify-center`}
							>
								<span className={`mr-2`}>{event.category === 'schools' ? 'Register for' : 'Buy'} tickets</span>
								<TicketIcon />
							</Link>
						{/*</Tippy>*/}
					</div>
				)}
			</div>
		)
	}

	return (
		<div className={`border-b-4 border-bbf-dark-green bg-black relative`}>
			<GatsbyImage
				image={event.local_event_img.childImageSharp.gatsbyImageData}
				alt={event.name}
				objectFit="contain"
				className={`max-h-screen-70 md:max-h-screen-50`} />
			<EventInfo event={event} />
		</div>
	)
}

export default FTEventBanner
