import React, { useState, useEffect } from 'react'
import LiveEventQandAForm from '../Event/LiveEventQandAForm'
import { convertIsoDateTime } from '../../utils/dates'

const PrismicLiveEvent = (props) => {

	const { event, livestreamData, pageLocation, eventFormName } = props
	const initialUrlParams = pageLocation.search.length ? pageLocation.search : ''

	const [showLivestream, setShowLivestream] = useState(false)
	const [hasRegistered, setHasRegistered] = useState(false)
	const [currentDate, setCurrentDate] = useState(new Date().getTime())

	// To make sure the Date will be created in Safari,
	// we need to replace - for / in the date string
	const eventStart = new Date(convertIsoDateTime(livestreamData.livestream_start_time)).getTime()
	const eventEnd = new Date(convertIsoDateTime(livestreamData.livestream_end_time)).getTime()

	// there's a boolean setting to show the question form in the Prismic livestreamEvent data.
	const showQuestionForm = livestreamData.livestream_question_form

	useEffect(() => {

		if (initialUrlParams.length) {
			const urlParams = new URLSearchParams(pageLocation.search)
			if (urlParams.get('id') === event.uuid) {
				// we can assume they have a link from registration at this point
				if (hasRegistered === false) {
					setHasRegistered(true)
				}

				const updateCurrentDate = setInterval(() => {
					setCurrentDate(new Date().getTime())
					// console.log(`currentDate: ${currentDate}`)
				}, 10000)

				if (showLivestream) {
					clearInterval(updateCurrentDate)
				}

				// check if we are within 1 hour of the event start
				const timeBeforeStart = 3600000 // 1 hour
				const dateDiff = eventStart - currentDate

				if (dateDiff <= timeBeforeStart) {
					if (showLivestream === false) {
						setShowLivestream(true)
					}
				}

				// TODO: This is for testing purposes, remove or comment out before release
				// setShowLivestream(true)

				return () => { clearInterval(updateCurrentDate) }
			}
		}
	}, [currentDate, eventStart, event.uuid, hasRegistered, initialUrlParams, pageLocation, showLivestream])

	const EventRegistration = () => {
		return null

		// if (hasRegistered) {
		// 	return null
		// }

		// return (
		// 	<EventMessageBanner
		// 		title={`Registration required.`}
		// 		description={<p>Tickets for online events at this years Festival are <strong>free of charge</strong>.<br/>
		// 			If you would like to watch this event, please register for tickets using the big yellow button above.</p>} />
		// )
	}

	const EventNotStarted = () => {
		if (showLivestream || !hasRegistered) {
			return null
		}

		return (
			<EventMessageBanner
				title={`You're Early!`}
				description={`Please come back again nearer the time, there will be things to watch up to an hour before the event starts.`} />
		)
	}

	const EventMessageBanner = (props) => {
		const { title, description } = props

		return (
			<div className={`p-4 md:p-12 border-b-4 border-bbf-dark-green`}>
				<h2	className={`text-3xl mb-6`}>{title}</h2>
				<h3 className={`text-2xl`}>{description}</h3>
			</div>
		)
	}


	const LivestreamVideo = (props) => {

		const { livestreamData } = props

		return (
			<div className="livestream-video-container w-full">
				<div className="livestream-video">
					{
						livestreamData.livestream_video.html ? (
							<div
								className="livestream-content embedVideo-container"
								dangerouslySetInnerHTML={{ __html: livestreamData.livestream_video.html }}
							/>
						) : (
							<p className={`text-2xl`}>Livestream not available.</p>
						)
					}
				</div>
				{/* Using the BSL link to direct to part two of an event here for the rerun */}
				{
					livestreamData.livestream_video_bsl && livestreamData.livestream_video_bsl.embed_url.length > 0 ? (
						<p className={`mt-6 text-2xl`}>
							<a className={`link`} href={livestreamData.livestream_video_bsl.embed_url} >Watch with BSL on youtube.com</a>
						</p>
					) : ``
				}
				{ livestreamData.livestream_supporting_content_1?.url && (
					<LivestreamSupportingContent
						url={livestreamData.livestream_supporting_content_1.url}
						name={livestreamData.livestream_supporting_content_1.raw.name} />
				) }
				{ livestreamData.livestream_supporting_content_2?.url && (
					<LivestreamSupportingContent
						url={livestreamData.livestream_supporting_content_2.url}
						name={livestreamData.livestream_supporting_content_2.raw.name} />
				) }
			</div>
		)
	}

	const LivestreamSupportingContent = (props) => {
		const { name, url } = props

		if (name.indexOf('.mp3') !== -1) {
			// render an audio player
			return (
				<figure className={`mt-6`}>
					<figcaption>{name}</figcaption>
					{/* no <track> element triggers an a11y warning, but it only matters for <video> so is safe to ignore */}
					<audio controls src={url} autoPlay={false}></audio>
				</figure>
			)
		}

		return (
			<p className={`mt-6 text-xl`}>
				<a className={`link`} href={url} >{name}</a>
			</p>
		)
	}

	const LivestreamSection = (props) => {
		// it's critical the props here are passed along so we don't
		// re-render the video unnecessarily and cause it to stop playing
		const { eventStart, eventEnd, eventFormName, livestreamData } = props

		if (!showLivestream) {
			return null
		}

		return (
			<div className={`lg:flex lg:items-start p-4 md:p-12 md:pb-16 md:mb-16 border-b-4 border-bbf-dark-green bg-white relative`}>
				<LivestreamVideo livestreamData={livestreamData} />
				{ showQuestionForm && <LiveEventQandAForm eventStart={eventStart} eventEnd={eventEnd} eventFormName={eventFormName}/> }
			</div>
		)
	}

	return (
		<>
			<LivestreamSection eventStart={eventStart} eventEnd={eventEnd} eventFormName={eventFormName} livestreamData={livestreamData} />
			<EventRegistration event={event} />
			<EventNotStarted event={event} />
		</>
	)

}

export default PrismicLiveEvent
