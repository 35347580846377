import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import FTEventBanner from '../components/Page/FTEventBanner'
import QandAForm from '../components/Form/QandAForm'
import PrismicLiveEvent from '../components/FTEvent/PrismicLiveEvent'
import { getCutoffDateForFestival } from "../utils/dates";

/**
 * TODO:
 *  - Add in a donate button to the event banner, next to the register for tickets, perhaps swap them if they've
 *  registered?
 */

export default function eventTemplate({
	data, // this prop will be injected by the GraphQL query below.
	location
}) {
	const ftEvent = data.ftEvent
	const eventFormName = ftEvent.name
	const livestream = data.livestreamEvent ? data.livestreamEvent : null
	const showQuestionForm = livestream?.data.livestream_question_form
	const { boxOfficeEnabled } = data.site.siteMetadata.config
	const cutoffDate = getCutoffDateForFestival(ftEvent.date.split('-')[0], ftEvent.category)
	const currentDate = new Date()


	const showLiveEvent = () => {
		return livestream && currentDate <= cutoffDate
	}

	const showRegistration = () => {
		return boxOfficeEnabled && currentDate <= cutoffDate;
	}

	const FTEvent = () => {
		return (
			<div className={`p-4 md:p-12 md:pt-0`}>
				<div className={`ft_event_content markdown-content md:columns-2 md:gap-12`} dangerouslySetInnerHTML={{__html: ftEvent.description}} />
			</div>
		)
	}

	return (
		<Layout>
			<FTEventBanner event={ftEvent} showRegistration={showRegistration()}/>
			{showLiveEvent() ? <PrismicLiveEvent event={ftEvent} eventFormName={eventFormName} livestreamData={livestream.data} pageLocation={location} /> : null}
			{/* add the Q&A form to the DOM so it is picked up by netlify */}
			{ showQuestionForm && (
				<div className={`hidden`}><QandAForm action={`/`} event={ftEvent} formName={eventFormName} /></div>
			)}
			<section className={`flex flex-row items-start flex-wrap md:pt-12 pb-16 bg-boswell-handwriting`}>
				<div className={`flex items-start flex-wrap w-full`}>
					<FTEvent />
				</div>
			</section>
		</Layout>
	)
}

export const pageQuery = graphql`query($ft_id: String!) {
	ftEvent: futureTicketingEvent (ft_id: { eq: $ft_id }) {
		id
		ft_id
		name
		uuid
		datetime:datetime(formatString:"YYYY/MM/DD HH:mm:ss")
		date:datetime(formatString:"YYYY-MM-DD")
		time:datetime(formatString:"h:mm a")
		description
		category
		img_path
		local_event_img {
			childImageSharp {
				gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
			}
		}
	}
	livestreamEvent: prismicLivestreamevent (data: { livestream_ft_id: { eq: $ft_id } }) {
		prismicId
		data {
			livestream_description {
				text
			}
			livestream_end_time
			livestream_ft_id
			livestream_question_form
			livestream_start_time
			livestream_title {
				text
			}
			livestream_video {
				embed_url
				html
			}
			livestream_video_bsl {
				embed_url
				html
			}
			livestream_supporting_content_1 {
				url
				raw
			}
			livestream_supporting_content_2 {
				url
				raw
			}
		}
		dataRaw
	}
	site {
		siteMetadata {
			title
			config {
				boxOfficeEnabled
				festivalYear
			}
		}
	}
}`
